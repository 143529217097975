import api from '.';

const REPORT_URL = `/admin/report`;

const getCreditUnionReports = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/credit-union`,
    null,
    params
  );
};

const getS3DownlaodUrlForReports = async (data, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${REPORT_URL}/credit-union/download`,
    data,
    null
  );
};

const getInvitationLogs = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/invitation-logs`,
    null,
    params
  );
};

const getInvitationsStatus = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/invitations-status`,
    null,
    params
  );
};

const getOperationalStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/operational/stats`,
    null,
    params
  );
};

const getInvitationOverallStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/invitations-overall-stats`,
    null,
    params
  );
};

const getInvitationSuccessStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/invitations-success-stats`,
    null,
    params
  );
};

const getInvitationStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/invitations-stats`,
    null,
    params
  );
};

const getDashboardStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/dashboard-stats`,
    null,
    params
  );
};

const getDashboardUserStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/dashboard-user-stats`,
    null,
    params
  );
};

const getDashboardUserCarStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/dashboard-user-car-stats`,
    null,
    params
  );
};

const getSupportContactUsStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/support-contactus/stats`,
    null,
    params
  );
};

const getInvitationDetails = async (data, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${REPORT_URL}/invitation-logs/details`,
    data,
    null
  );
};

const getClientsStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/clients/stats`,
    null,
    params
  );
};

const getSellingDealers = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/clients/selling-dealers`,
    null,
    params
  );
};

const getCancellationStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/subscriptions/cancellation-stats`,
    null,
    params
  );
};

const getCancellationList = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/subscriptions/cancellation-list`,
    null,
    params
  );
};

const getEmailsListForOffer = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/subscriptions/email-list-for-offer`,
    null,
    params
  );
};

const getS3AssetSignedUrl = async (dispatch, data) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${REPORT_URL}/assets/signed-url`,
    data
  );
};

const getS3ObjectStringContent = async (dispatch, data) => {
  return api.pocketDriveApi(
    dispatch,
    'post',
    `${REPORT_URL}/assets/s3Object/content`,
    data
  );
};

const getRepairBookingsDashboardStats = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/repair-bookings/dashboard`,
    null,
    params
  );
};

const getRepairBookingsList = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/repair-bookings/list`,
    null,
    params
  );
};

const getWcfmcCallbackList = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/repair-bookings/callbacks`,
    null,
    params
  );
};

const getClaimUpdatesList = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/claimUpdates/list`,
    null,
    params
  );
};

const getGlowUpdateCallbacksList = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/glowCallbacks/list`,
    null,
    params
  );
};

const getConversationList = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/conversations/list`,
    null,
    params
  );
};

const getVcpMuleApiLogs = async (params, dispatch) => {
  return api.pocketDriveApi(
    dispatch,
    'get',
    `${REPORT_URL}/vcp-mule-api-logs/list`,
    null,
    params
  );
};

export default {
  getCreditUnionReports,
  getInvitationLogs,
  getInvitationsStatus,
  getOperationalStats,
  getInvitationStats,
  getS3DownlaodUrlForReports,
  getInvitationOverallStats,
  getInvitationSuccessStats,
  getDashboardStats,
  getDashboardUserStats,
  getDashboardUserCarStats,
  getSupportContactUsStats,
  getInvitationDetails,
  getClientsStats,
  getSellingDealers,
  getCancellationStats,
  getCancellationList,
  getEmailsListForOffer,
  getS3AssetSignedUrl,
  getS3ObjectStringContent,
  getRepairBookingsDashboardStats,
  getRepairBookingsList,
  getWcfmcCallbackList,
  getClaimUpdatesList,
  getGlowUpdateCallbacksList,
  getConversationList,
  getVcpMuleApiLogs,
};
