import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AppBar,
  IconButton,
  Typography,
  Button,
  CircularProgress,
  Toolbar,
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Divider,
  Collapse,
  Card,
  Grid,
} from '@mui/material';
import {
  Menu,
  AccountCircle,
  DirectionsCar,
  Error,
  Note,
  CropFree,
  Assessment,
  NetworkCheck,
  GroupAdd,
  PersonAdd,
  ErrorRounded,
  ContactSupportRounded,
  SmsFailedRounded,
  ContactSupportOutlined,
  Settings,
  Dashboard,
  Storage,
  Drafts,
  Description,
  ExpandLess,
  ExpandMore,
  Language,
  Badge,
  StairsTwoTone,
  RecentActorsSharp,
  Subscriptions,
  PaymentTwoTone,
  RateReview,
  ErrorOutlined,
  QueryBuilder,
  FilePresentTwoTone,
  CancelPresentationOutlined,
  Dock,
  InsertInvitation,
  ScheduleOutlined,
  CallMadeRounded,
  CallMerge,
  Bookmark,
  ChatBubbleTwoTone,
  BadgeTwoTone,
  ManageAccounts,
  AccountTreeOutlined,
  AccountCircleRounded,
  CloudTwoTone,
  DomainTwoTone,
  PublicTwoTone,
  LanguageTwoTone,
  PortraitTwoTone,
  GroupTwoTone,
  TuneTwoTone,
  ContactMailTwoTone,
  PhoneCallbackRounded,
  ScheduleRounded,
  ScheduleSendOutlined,
  ScheduleSharp,
  LiveHelpSharp,
  QuestionAnswerSharp,
  LogoDevTwoTone,
  LogoDevOutlined,
  LocalOffer,
  Sync,
  BadgeOutlined,
  EmailRounded,
} from '@mui/icons-material';

import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import JSONViewer from './JSONViewer';
import { UK, US } from '../assets/images';
import { updateSelectedCountryAction } from '../store/actions/app.actions';

const STUCK_IN_PENDING_LIMIT = 5000;
const isUK = process.env.REACT_APP_DEFAULT_REGION === 'UK';

let timeout;

function AdminNavBar() {
  const { authState, oktaAuth } = useOktaAuth();
  const [showMenu, setShowMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const currentUser = useSelector((state) => state.currentUser.currentUser);
  const permissions = useSelector((state) =>
    typeof state.permissions.permissions !== typeof undefined
      ? state.permissions.permissions
      : null
  );
  const navigate = useNavigate();
  const login = async () => oktaAuth.signInWithRedirect();
  const logout = async () =>
    oktaAuth.signOut({
      postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
    });
  const toggleMenu = () => setShowMenu(!showMenu);
  const toggleUserMenu = () => setShowUserMenu(!showUserMenu);
  const [categories, setcategories] = useState({
    invitation: false,
    reports: false,
    data: false,
    subscriptions: false,
    settings: false,
    logs: false,
    contactUs: false,
    welcomekitreports: false,
    trustagereports: false,
    updateCallbacks: false,
    manageadmin: false,
    scheduledjobs: false,
    faqs: false,
  });
  const goTo = (route) => {
    navigate(route);
    toggleMenu();
  };

  const toggleOpenState = (category) => {
    if (Object.prototype.hasOwnProperty.call(categories, category)) {
      categories[category] = !categories[category];
      setcategories({ ...categories });
    }
  };

  const renderLeftMenuDrawer = () => {
    return (
      <FullDrawer
        authState={authState}
        showMenu={showMenu}
        toggleMenu={toggleMenu}
        toggleOpenState={toggleOpenState}
        goTo={goTo}
        categories={categories}
        permissions={permissions}
      />
    );
  };

  const renderLoginButton = () => {
    if (authState && authState.isPending) {
      timeout = setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      }, STUCK_IN_PENDING_LIMIT);
      return <CircularProgress color="secondary" />;
    }
    if (timeout) {
      clearTimeout(timeout);
    }

    return (
      <Button variant="contained" color="secondary" onClick={login}>
        Sign in
      </Button>
    );
  };

  const renderUserMenuDrawer = () => {
    return (
      <UserMenuDrawer
        authState={authState}
        showUserMenu={showUserMenu}
        toggleUserMenu={toggleUserMenu}
        toggleOpenState={toggleOpenState}
        currentUser={currentUser}
        permissions={permissions}
        goTo={goTo}
        logout={logout}
      />
    );
  };

  return (
    <>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleMenu}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" className="flex-grow-1">
            Vehicle Care Online - Admin Portal
          </Typography>
          <Typography style={{ margin: 5 }}>
            {authState && authState.isAuthenticated && currentUser !== undefined
              ? currentUser.name
              : renderLoginButton()}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="user"
            onClick={toggleUserMenu}
          >
            <AccountCircleRounded />
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderLeftMenuDrawer()}
      {renderUserMenuDrawer()}
    </>
  );
}

function UserMenuDrawer({
  authState,
  showUserMenu,
  toggleUserMenu,
  currentUser,
  permissions,
  logout,
}) {
  let regionIcon = US;
  const country = useSelector((state) =>
    typeof state.app.selectedCountry !== typeof undefined
      ? state.app.selectedCountry
      : null
  );
  const [selectedCountry, setSelectedCountry] = useState(country);
  const dispatch = useDispatch();
  if (process.env.REACT_APP_DEFAULT_REGION === 'UK') {
    regionIcon = UK;
  }

  useEffect(() => {
    setSelectedCountry(country);
  }, [country]);

  const handleCountryChange = async (event) => {
    const newCountry = event.target.value;
    setSelectedCountry(newCountry);
    await dispatch(updateSelectedCountryAction(newCountry));
    sessionStorage.setItem('selectedCountry', newCountry);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <Drawer anchor="right" open={showUserMenu} onClose={toggleUserMenu}>
      {authState && authState.isAuthenticated && currentUser && (
        <Grid
          container
          spacing={10}
          style={{ margin: 10, padding: 10 }}
          justify="left"
        >
          <Card style={{ marginTop: 10 }} variant="outlined" className="p-1">
            <Card style={{ marginTop: 10 }} variant="elevation" className="p-1">
              <PortraitTwoTone /> {currentUser.name}
            </Card>
            <Card style={{ marginTop: 10 }} variant="elevation" className="p-1">
              <GroupTwoTone /> {currentUser.adminAssumedRole}
            </Card>
            <Card style={{ marginTop: 10 }} variant="elevation" className="p-1">
              <ContactMailTwoTone /> Okta Profile
              <JSONViewer
                title="Okta Profile"
                jsonData={currentUser.oktaProfile}
              />
            </Card>
            <Card style={{ marginTop: 10 }} variant="elevation" className="p-1">
              <TuneTwoTone /> Permissions
              <JSONViewer title="Permissions" jsonData={permissions} />
            </Card>
            <Card style={{ marginTop: 10 }} variant="elevation" className="p-1">
              <TuneTwoTone /> Region
              <select
                defaultValue={selectedCountry}
                onChange={handleCountryChange}
              >
                {currentUser.adminAllowedRegions &&
                currentUser.adminAllowedRegions.length > 1 ? (
                  currentUser.adminAllowedRegions.map((region) => (
                    <option key={region} value={region}>
                      {region}
                    </option>
                  ))
                ) : (
                  <option value="None">None</option>
                )}
              </select>
            </Card>

            <Card style={{ marginTop: 10 }} variant="elevation" className="p-1">
              <LanguageTwoTone /> Accessible Data Regions :{' '}
              {currentUser.adminAllowedRegions &&
              currentUser.adminAllowedRegions.length
                ? currentUser.adminAllowedRegions.join(' ,')
                : 'None'}
            </Card>
            <br />
            {authState && authState.isAuthenticated && (
              <Button
                variant="contained"
                color="secondary"
                style={{ marginTop: 10, width: '100%' }}
                onClick={logout}
              >
                Sign out
              </Button>
            )}
            <br />
            <br />
            <Card style={{ marginTop: 10 }} variant="elevation" className="p-1">
              <Card
                style={{ marginTop: 10 }}
                variant="elevation"
                className="p-1"
              >
                <CloudTwoTone /> Version: {process.env.REACT_APP_ADMIN_VERSION}
              </Card>
              <Card
                style={{ marginTop: 10 }}
                variant="elevation"
                className="p-1"
              >
                <DomainTwoTone /> Environment: {process.env.REACT_APP_ENV}
              </Card>
              <Card
                style={{ marginTop: 10 }}
                variant="elevation"
                className="p-1"
              >
                <PublicTwoTone /> Server Region:{' '}
                <img className="region-icon" src={regionIcon} alt="region" />
              </Card>
            </Card>
          </Card>
        </Grid>
      )}
    </Drawer>
  );
}

function FullDrawer({
  authState,
  showMenu,
  toggleMenu,
  toggleOpenState,
  goTo,
  categories,
  permissions,
}) {
  return (
    <Drawer anchor="left" open={showMenu} onClose={toggleMenu}>
      {authState &&
        authState.isAuthenticated &&
        permissions &&
        Object.keys(permissions).length && (
          <List>
            {permissions.DASHBOARD.READ && (
              <ListItem
                className="left-menu-item"
                button
                onClick={() => goTo('/dashboard')}
              >
                <ListItemIcon>
                  <Dashboard />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            )}
            <Divider />
            {permissions.OPERATIONAL_STATS.READ && (
              <ListItem
                className="left-menu-item"
                button
                onClick={() => goTo('/operationalStats')}
              >
                <ListItemIcon>
                  <Dashboard />
                </ListItemIcon>
                <ListItemText primary="Operational Stats" />
              </ListItem>
            )}
            <Divider />
            {(permissions.USERS.READ ||
              permissions.USERS.READ ||
              permissions.CARS.READ ||
              permissions.INVITATIONS.READ ||
              permissions.CARS_WITH_DATA_ISSUES.READ) && (
              <ListItem
                className="left-menu-item"
                button
                onClick={() => {
                  toggleOpenState('data');
                }}
              >
                <ListItemIcon>
                  <Storage />
                </ListItemIcon>
                <ListItemText primary="Data" />
                {categories.data ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            <Collapse in={categories.data} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                style={{ paddingLeft: '10px' }}
              >
                {permissions && permissions.USERS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/users')}
                  >
                    <ListItemIcon>
                      <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItem>
                )}
                {permissions && permissions.CARS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/cars')}
                  >
                    <ListItemIcon>
                      <DirectionsCar />
                    </ListItemIcon>
                    <ListItemText primary="Cars" />
                  </ListItem>
                )}
                {permissions && permissions.INVITATIONS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/invitations')}
                  >
                    <ListItemIcon>
                      <Drafts />
                    </ListItemIcon>
                    <ListItemText primary="Invitations" />
                  </ListItem>
                )}
                {permissions && permissions.LOCKED_CARS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/lockedCars')}
                  >
                    <ListItemIcon>
                      <DirectionsCar />
                    </ListItemIcon>
                    <ListItemText primary="Locked Cars" />
                  </ListItem>
                )}
                {permissions && permissions.DEALER_PROFILES.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/dealer-portal')}
                  >
                    <ListItemIcon>
                      <Drafts />
                    </ListItemIcon>
                    <ListItemText primary="Dealer Profiles" />
                  </ListItem>
                )}
                {permissions && permissions.CARS_WITH_DATA_ISSUES.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/carIssues')}
                  >
                    <ListItemIcon>
                      <ErrorRounded />
                    </ListItemIcon>
                    <ListItemText primary="Car Issues" />
                  </ListItem>
                )}
                {permissions && permissions.CLIENTS_STATS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/clients-stats')}
                  >
                    <ListItemIcon>
                      <StairsTwoTone />
                    </ListItemIcon>
                    <ListItemText primary="Clients Stats" />
                  </ListItem>
                )}
              </List>
            </Collapse>
            <Divider />
            {permissions && permissions.FAQS.READ && (
              <ListItem
                className="left-menu-item"
                button
                onClick={() => {
                  toggleOpenState('faqs');
                }}
              >
                <ListItemIcon>
                  <LiveHelpSharp />
                </ListItemIcon>
                <ListItemText primary="FAQ" />
                {categories.faqs ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            <Collapse in={categories.faqs} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                style={{ paddingLeft: '10px' }}
              >
                {permissions && permissions.FAQS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/faqs-questions')}
                  >
                    <ListItemIcon>
                      <QuestionAnswerSharp />
                    </ListItemIcon>
                    <ListItemText primary="FAQ Question Answer" />
                  </ListItem>
                )}
                {permissions && permissions.FAQS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/faqs-tags')}
                  >
                    <ListItemIcon>
                      <LocalOffer />
                    </ListItemIcon>
                    <ListItemText primary="FAQ Tags" />
                  </ListItem>
                )}
                {permissions && permissions.FAQS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/faqs-mapping')}
                  >
                    <ListItemIcon>
                      <Sync />
                    </ListItemIcon>
                    <ListItemText primary="FAQ Mapping" />
                  </ListItem>
                )}
              </List>
            </Collapse>
            <Divider />
            {permissions.OFFERS_DATA.READ && (
              <ListItem
                className="left-menu-item"
                button
                onClick={() => {
                  toggleOpenState('subscriptions');
                }}
              >
                <ListItemIcon>
                  <Subscriptions />
                </ListItemIcon>
                <ListItemText
                  primary={
                    process.env.REACT_APP_DEFAULT_REGION === 'UK'
                      ? 'Renewals'
                      : 'Subscriptions'
                  }
                />
                {categories.subscriptions ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            <Collapse
              in={categories.subscriptions}
              timeout="auto"
              unmountOnExit
            >
              <List
                component="div"
                disablePadding
                style={{ paddingLeft: '10px' }}
              >
                {permissions && permissions.SUBSCRIPTIONS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/file-logs')}
                  >
                    <ListItemIcon>
                      <FilePresentTwoTone />
                    </ListItemIcon>
                    <ListItemText primary="File Logs" />
                  </ListItem>
                )}
                {permissions && permissions.OFFERS_DATA.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/offers')}
                  >
                    <ListItemIcon>
                      <Badge />
                    </ListItemIcon>
                    <ListItemText primary="Offers" />
                  </ListItem>
                )}
                {permissions && permissions.OFFERS_DATA.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/activated-offers')}
                  >
                    <ListItemIcon>
                      <BadgeOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Activated Offers" />
                  </ListItem>
                )}
                {permissions && permissions.SUPPORT.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/logs/subscription-email-logs')}
                  >
                    <ListItemIcon>
                      <EmailRounded />
                    </ListItemIcon>
                    <ListItemText primary="Email Logs" />
                  </ListItem>
                )}
                {permissions && permissions.SUBSCRIPTIONS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/transactions')}
                  >
                    <ListItemIcon>
                      <RecentActorsSharp />
                    </ListItemIcon>
                    <ListItemText primary="Transactions" />
                  </ListItem>
                )}
                {permissions &&
                  permissions.SUBSCRIPTIONS.READ &&
                  process.env.REACT_APP_DEFAULT_REGION === 'US' && (
                    <ListItem
                      className="left-menu-item"
                      button
                      onClick={() => goTo('/payment-responses')}
                    >
                      <ListItemIcon>
                        <PaymentTwoTone />
                      </ListItemIcon>
                      <ListItemText primary="Payment Responses" />
                    </ListItem>
                  )}
                {permissions && permissions.SUBSCRIPTIONS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/quotes-list')}
                  >
                    <ListItemIcon>
                      <RateReview />
                    </ListItemIcon>
                    <ListItemText primary="Quotes" />
                  </ListItem>
                )}
                {permissions && permissions.SUBSCRIPTIONS.WRITE && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/api-errors')}
                  >
                    <ListItemIcon>
                      <ErrorOutlined />
                    </ListItemIcon>
                    <ListItemText primary="API Errors" />
                  </ListItem>
                )}
                {permissions &&
                  permissions.SUBSCRIPTIONS.READ &&
                  process.env.REACT_APP_DEFAULT_REGION === 'US' && (
                    <ListItem
                      className="left-menu-item"
                      button
                      onClick={() => goTo('/subscription-cancellations')}
                    >
                      <ListItemIcon>
                        <CancelPresentationOutlined />
                      </ListItemIcon>
                      <ListItemText primary="Cancellations" />
                    </ListItem>
                  )}
                {permissions && permissions.RENEWALS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/subscriptionsReport')}
                  >
                    <ListItemIcon>
                      <Assessment />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        process.env.REACT_APP_DEFAULT_REGION === 'UK'
                          ? 'Renewals Report'
                          : 'Subscriptions Report'
                      }
                    />
                  </ListItem>
                )}
                {permissions && permissions.RENEWALS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/subscriptionDealerReport')}
                  >
                    <ListItemIcon>
                      <Assessment />
                    </ListItemIcon>
                    <ListItemText primary="Dealer Report" />
                  </ListItem>
                )}
                {permissions && permissions.RENEWALS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/subscriptionErrors')}
                  >
                    <ListItemIcon>
                      <Assessment />
                    </ListItemIcon>
                    <ListItemText primary="Error Dashboard" />
                  </ListItem>
                )}
                {permissions && permissions.SUBSCRIPTIONS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/offer-logs')}
                  >
                    <ListItemIcon>
                      <PaymentTwoTone />
                    </ListItemIcon>
                    <ListItemText primary="Offer File Logs" />
                  </ListItem>
                )}
                {permissions && permissions.SUBSCRIPTIONS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/recurring-file-logs')}
                  >
                    <ListItemIcon>
                      <PaymentTwoTone />
                    </ListItemIcon>
                    <ListItemText primary="Recurring File Logs" />
                  </ListItem>
                )}
                {permissions && permissions.SUBSCRIPTIONS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/recurring-journey-logs')}
                  >
                    <ListItemIcon>
                      <PaymentTwoTone />
                    </ListItemIcon>
                    <ListItemText primary="Recurring Processing Logs" />
                  </ListItem>
                )}
                {permissions && permissions.SUBSCRIPTIONS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/schedule-jobs')}
                  >
                    <ListItemIcon>
                      <PaymentTwoTone />
                    </ListItemIcon>
                    <ListItemText primary="Scheduled Jobs" />
                  </ListItem>
                )}
                {permissions && permissions.SUBSCRIPTIONS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/archive-schedule-jobs')}
                  >
                    <ListItemIcon>
                      <PaymentTwoTone />
                    </ListItemIcon>
                    <ListItemText primary="Archive Scheduled Jobs" />
                  </ListItem>
                )}
                {permissions && permissions.SQL_EDITOR.WRITE && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/query-editor')}
                  >
                    <ListItemIcon>
                      <QueryBuilder />
                    </ListItemIcon>
                    <ListItemText primary="Query Editor" />
                  </ListItem>
                )}
                {permissions &&
                  (permissions.STATUS.READ || permissions.APP_CONFIG.READ) && (
                    <ListItem
                      className="left-menu-item"
                      button
                      onClick={() => {
                        toggleOpenState('settings');
                      }}
                    >
                      <ListItemIcon>
                        <Settings />
                      </ListItemIcon>
                      <ListItemText primary="Settings" />
                      {categories.settings ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                  )}
                <Collapse in={categories.settings} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    style={{ paddingLeft: '10px' }}
                  >
                    {permissions && permissions.STATUS.READ && (
                      <ListItem
                        className="left-menu-item"
                        button
                        onClick={() => goTo('/subscriptionStatus')}
                      >
                        <ListItemIcon>
                          <NetworkCheck />
                        </ListItemIcon>
                        <ListItemText primary="Status" />
                      </ListItem>
                    )}
                    {permissions && permissions.APP_CONFIG.READ && (
                      <ListItem
                        className="left-menu-item"
                        button
                        onClick={() => goTo('/subscription-app-config')}
                      >
                        <ListItemIcon>
                          <Settings />
                        </ListItemIcon>
                        <ListItemText primary="App Config" />
                      </ListItem>
                    )}
                  </List>
                </Collapse>
              </List>
            </Collapse>

            <Divider />
            {permissions &&
              (permissions.INVITATIONS_LOGS.READ ||
                permissions.SMS_LOGS.READ ||
                permissions.ERROR_LOGS.READ) && (
                <ListItem
                  className="left-menu-item"
                  button
                  onClick={() => {
                    toggleOpenState('logs');
                  }}
                >
                  <ListItemIcon>
                    <Description />
                  </ListItemIcon>
                  <ListItemText primary="Logs" />
                  {categories.logs ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              )}

            <Collapse in={categories.logs} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                style={{ paddingLeft: '10px' }}
              >
                {permissions && permissions.INVITATIONS_LOGS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/invitation-logs')}
                  >
                    <ListItemIcon>
                      <Description />
                    </ListItemIcon>
                    <ListItemText primary="Invitation Logs" />
                  </ListItem>
                )}
                {permissions && permissions.SMS_LOGS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/smsLogs')}
                  >
                    <ListItemIcon>
                      <Note />
                    </ListItemIcon>
                    <ListItemText primary="SMS Logs" />
                  </ListItem>
                )}
                {permissions && permissions.ERROR_LOGS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/errors')}
                  >
                    <ListItemIcon>
                      <Error />
                    </ListItemIcon>
                    <ListItemText primary="Errors" />
                  </ListItem>
                )}
              </List>
            </Collapse>

            <Divider />
            {permissions &&
              (permissions.USER_REPORTS.READ ||
                permissions.CREDIT_UNION_REPORTS.READ) && (
                <ListItem
                  className="left-menu-item"
                  button
                  onClick={() => {
                    toggleOpenState('reports');
                  }}
                >
                  <ListItemIcon>
                    <Assessment />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                  {categories.reports ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              )}
            <Collapse in={categories.reports} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                style={{ paddingLeft: '10px' }}
              >
                {permissions && permissions.USER_REPORTS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/reports')}
                  >
                    <ListItemIcon>
                      <Assessment />
                    </ListItemIcon>
                    <ListItemText primary="User Reports" />
                  </ListItem>
                )}
                {permissions && permissions.CREDIT_UNION_REPORTS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/creditunionreports')}
                  >
                    <ListItemIcon>
                      <Assessment />
                    </ListItemIcon>
                    <ListItemText primary="Credit Union Reports" />
                  </ListItem>
                )}
              </List>
            </Collapse>
            <Divider />

            {permissions && permissions.WELCOME_KIT_REPORTS.READ && (
              <ListItem
                className="left-menu-item"
                button
                onClick={() => {
                  toggleOpenState('welcomekitreports');
                }}
              >
                <ListItemIcon>
                  <Dock />
                </ListItemIcon>
                <ListItemText primary="Welcome Kit Report" />
                {categories.welcomekitreports ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            <Collapse
              in={categories.welcomekitreports}
              timeout="auto"
              unmountOnExit
            >
              <List
                component="div"
                disablePadding
                style={{ paddingLeft: '10px' }}
              >
                {permissions && permissions.WELCOME_KIT_REPORTS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/welcomekitdashboard')}
                  >
                    <ListItemIcon>
                      <Dashboard />
                    </ListItemIcon>
                    <ListItemText primary="Welcome Kit Dashboard" />
                  </ListItem>
                )}
                {permissions && permissions.WELCOME_KIT_REPORTS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/welcomekitinvitations')}
                  >
                    <ListItemIcon>
                      <InsertInvitation />
                    </ListItemIcon>
                    <ListItemText primary="Welcome Kit Invitations" />
                  </ListItem>
                )}
              </List>
            </Collapse>
            <Divider />

            {permissions && permissions.WELCOME_KIT_REPORTS.READ && (
              <ListItem
                className="left-menu-item"
                button
                onClick={() => {
                  toggleOpenState('trustagereports');
                }}
              >
                <ListItemIcon>
                  <BadgeTwoTone />
                </ListItemIcon>
                <ListItemText primary="TruStage Report" />
                {categories.trustagereports ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            <Collapse
              in={categories.trustagereports}
              timeout="auto"
              unmountOnExit
            >
              <List
                component="div"
                disablePadding
                style={{ paddingLeft: '10px' }}
              >
                {permissions && permissions.WELCOME_KIT_REPORTS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/tru-stage-dashboard')}
                  >
                    <ListItemIcon>
                      <Dashboard />
                    </ListItemIcon>
                    <ListItemText primary="TruStage Dashboard" />
                  </ListItem>
                )}
                {permissions && permissions.WELCOME_KIT_REPORTS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/tru-stage-invitations')}
                  >
                    <ListItemIcon>
                      <InsertInvitation />
                    </ListItemIcon>
                    <ListItemText primary="TruStage Invitations" />
                  </ListItem>
                )}
              </List>
            </Collapse>
            <Divider />

            {permissions &&
              (permissions.DEALER_QR_CODE.READ ||
                permissions.BULK_INVITATION.READ ||
                permissions.ADMIN_MANUAL_INVITATION.READ) && (
                <ListItem
                  className="left-menu-item"
                  button
                  onClick={() => {
                    toggleOpenState('invitation');
                  }}
                >
                  <ListItemIcon>
                    <PersonAdd />
                  </ListItemIcon>
                  <ListItemText primary="Create Invitation" />
                  {categories.invitation ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              )}
            <Collapse in={categories.invitation} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                style={{ paddingLeft: '10px' }}
              >
                {permissions && permissions.DEALER_QR_CODE.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/dealerCodes')}
                  >
                    <ListItemIcon>
                      <CropFree />
                    </ListItemIcon>
                    <ListItemText primary="Dealer QR Codes" />
                  </ListItem>
                )}
                {permissions && permissions.ADMIN_MANUAL_INVITATION.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/sendInvitation')}
                  >
                    <ListItemIcon>
                      <Drafts />
                    </ListItemIcon>
                    <ListItemText primary="Admin Manual Invitation" />
                  </ListItem>
                )}
                {permissions && permissions.BULK_INVITATION.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/bulk-invitations')}
                  >
                    <ListItemIcon>
                      <GroupAdd />
                    </ListItemIcon>
                    <ListItemText primary="Bulk Invitations" />
                  </ListItem>
                )}
              </List>
            </Collapse>
            <Divider />
            {permissions && permissions.OPERATIONAL_STATS.READ && (
              <ListItem
                className="left-menu-item"
                button
                onClick={() => {
                  toggleOpenState('scheduledjobs');
                }}
              >
                <ListItemIcon>
                  <ScheduleRounded />
                </ListItemIcon>
                <ListItemText primary="Scheduled Jobs" />
                {categories.scheduledjobs ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            <Collapse
              in={categories.scheduledjobs}
              timeout="auto"
              unmountOnExit
            >
              <List
                component="div"
                disablePadding
                style={{ paddingLeft: '10px' }}
              >
                {permissions && permissions.OPERATIONAL_STATS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/vcp-scheduled-jobs')}
                  >
                    <ListItemIcon>
                      <ScheduleSendOutlined />
                    </ListItemIcon>
                    <ListItemText primary="VCP Core Jobs" />
                  </ListItem>
                )}
                {permissions && permissions.OPERATIONAL_STATS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/vcp-scheduled-job-archives')}
                  >
                    <ListItemIcon>
                      <ScheduleSharp />
                    </ListItemIcon>
                    <ListItemText primary="VCP Core Job Archives" />
                  </ListItem>
                )}
              </List>
            </Collapse>
            <Divider />

            {permissions && permissions.CONTACT_US.READ && (
              <ListItem
                className="left-menu-item"
                button
                onClick={() => {
                  toggleOpenState('contactUs');
                }}
              >
                <ListItemIcon>
                  <ContactSupportRounded />
                </ListItemIcon>
                <ListItemText primary="Contact Us" />
                {categories.contactUs ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            <Collapse in={categories.contactUs} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                style={{ paddingLeft: '10px' }}
              >
                {permissions && permissions.SUPPORT.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/support/stats')}
                  >
                    <ListItemIcon>
                      <ContactSupportOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Support Stats" />
                  </ListItem>
                )}
                {permissions && permissions.SUPPORT.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/support/conversations')}
                  >
                    <ListItemIcon>
                      <ChatBubbleTwoTone />
                    </ListItemIcon>
                    <ListItemText primary="Conversations" />
                  </ListItem>
                )}
                {permissions && permissions.CONTACT_US.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/contactUs/failed-requests')}
                  >
                    <ListItemIcon>
                      <SmsFailedRounded />
                    </ListItemIcon>
                    <ListItemText primary="Failed Requests" />
                  </ListItem>
                )}
              </List>
            </Collapse>
            <Divider />
            {isUK && permissions && permissions.SUPPORT.READ && (
              <ListItem
                className="left-menu-item"
                button
                onClick={() => goTo('/repair-bookings/dashboard')}
              >
                <ListItemIcon>
                  <ScheduleOutlined />
                </ListItemIcon>
                <ListItemText primary="Repair Bookings" />
              </ListItem>
            )}
            <Divider />

            {permissions && permissions.DASHBOARD.READ && (
              <ListItem
                className="left-menu-item"
                button
                onClick={() => {
                  toggleOpenState('updateCallbacks');
                }}
              >
                <ListItemIcon>
                  <CallMadeRounded />
                </ListItemIcon>
                <ListItemText primary="Update/Callback Logs" />
                {categories.updateCallbacks ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
            )}
            <Collapse
              in={categories.updateCallbacks}
              timeout="auto"
              unmountOnExit
            >
              <List
                component="div"
                disablePadding
                style={{ paddingLeft: '10px' }}
              >
                {permissions && permissions.DASHBOARD.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/updates/glowUpdateCallbacks')}
                  >
                    <ListItemIcon>
                      <CallMerge />
                    </ListItemIcon>
                    <ListItemText primary="Glow Update Callbacks" />
                  </ListItem>
                )}
                {permissions && permissions.DASHBOARD.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/updates/glowClaimUpdates')}
                  >
                    <ListItemIcon>
                      <Bookmark />
                    </ListItemIcon>
                    <ListItemText primary="Glow Claim Updates" />
                  </ListItem>
                )}
                {permissions && permissions.SUPPORT.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/logs/vcp-mule-api-logs')}
                  >
                    <ListItemIcon>
                      <LogoDevTwoTone />
                    </ListItemIcon>
                    <ListItemText primary="VCP Mule API Logs" />
                  </ListItem>
                )}
                {permissions && permissions.SUPPORT.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/logs/vcp-subscription-mule-api-logs')}
                  >
                    <ListItemIcon>
                      <LogoDevOutlined />
                    </ListItemIcon>
                    <ListItemText primary="VCP Subscription Mule API Logs" />
                  </ListItem>
                )}
                {permissions && permissions.SUPPORT.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/subscription-callback-logs')}
                  >
                    <ListItemIcon>
                      <PhoneCallbackRounded />
                    </ListItemIcon>
                    <ListItemText primary="Subscription Callback Logs" />
                  </ListItem>
                )}
              </List>
            </Collapse>
            <Divider />

            {permissions &&
              (permissions.STATUS.READ ||
                permissions.APP_CONFIG.READ ||
                permissions.BROADCAST_MESSAGE.READ ||
                permissions.CAR_BACKGROUNDS.READ) && (
                <ListItem
                  className="left-menu-item"
                  button
                  onClick={() => {
                    toggleOpenState('settings');
                  }}
                >
                  <ListItemIcon>
                    <Settings />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                  {categories.settings ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              )}
            <Collapse in={categories.settings} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                style={{ paddingLeft: '10px' }}
              >
                {permissions && permissions.STATUS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/status')}
                  >
                    <ListItemIcon>
                      <NetworkCheck />
                    </ListItemIcon>
                    <ListItemText primary="Status" />
                  </ListItem>
                )}

                {permissions && permissions.APP_CONFIG.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/app-config')}
                  >
                    <ListItemIcon>
                      <Settings />
                    </ListItemIcon>
                    <ListItemText primary="App Config" />
                  </ListItem>
                )}
                {permissions &&
                  permissions.BUSINESS_CONFIG &&
                  permissions.BUSINESS_CONFIG.READ && (
                    <ListItem
                      className="left-menu-item"
                      button
                      onClick={() => goTo('/business-config')}
                    >
                      <ListItemIcon>
                        <Settings />
                      </ListItemIcon>
                      <ListItemText primary="Business Config" />
                    </ListItem>
                  )}
                {permissions && permissions.CAR_BACKGROUNDS.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/car-backgrounds')}
                  >
                    <ListItemIcon>
                      <DirectionsCar />
                    </ListItemIcon>
                    <ListItemText primary="Car Backgrounds" />
                  </ListItem>
                )}
                {permissions && permissions.BROADCAST_MESSAGE.WRITE && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => goTo('/sendBroadcast')}
                  >
                    <ListItemIcon>
                      <Language />
                    </ListItemIcon>
                    <ListItemText primary="Broadcast Messages" />
                  </ListItem>
                )}
                {permissions && permissions.SUPER_ADMIN.READ && (
                  <ListItem
                    className="left-menu-item"
                    button
                    onClick={() => {
                      toggleOpenState('manageadmin');
                    }}
                  >
                    <ListItemIcon>
                      <ManageAccounts />
                    </ListItemIcon>
                    <ListItemText primary="Manage" />
                    {categories.manageadmin ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                )}
                <Collapse
                  in={categories.manageadmin}
                  timeout="auto"
                  unmountOnExit
                >
                  <List
                    component="div"
                    disablePadding
                    style={{ paddingLeft: '10px' }}
                  >
                    {permissions && permissions.SUPER_ADMIN.READ && (
                      <ListItem
                        className="left-menu-item"
                        button
                        onClick={() => goTo('/manage/admin')}
                      >
                        <ListItemIcon>
                          <AccountTreeOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Admin Users" />
                      </ListItem>
                    )}
                  </List>
                </Collapse>
              </List>
            </Collapse>
          </List>
        )}
    </Drawer>
  );
}

export default AdminNavBar;
